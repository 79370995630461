import React, { useEffect, useState } from "react";
// import AddQuestionSets from '../qtemplate/addSets';
import { Paper } from "@mui/material";
import api from "../../API/api";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PaperForm from "./form";
import { useLocation, useNavigate } from "react-router-dom";

// import QuestionForm from '../question/form';

const CreatePaper = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [table, setTable] = useState();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [backdroploader, setBackdropLoader] = useState(false);
  const handleClose = () => {
    setBackdropLoader(false);
  };
  const [count, setCount] = useState(0);
  const [subject, setSubject] = useState();
  const [syllabus, setSyllabus] = useState();
  // add those syllabus
  const [sections, setSections] = useState([]);
  const [questionForm, setQuestionForm] = useState({
    subject_id: "",
    syllabus_id: "",
  });
  const [sectionQues, setSectionQues] = useState(0);
  const [template, setTemplate] = useState({
    name: "",
    description: "",
    tot_questions: 100,
    marks_per_question: 1,
    negative_marks: 0,
    paper_price: 0,
    live_test_date: 0,
    expire_date: 0,
    live_test_time: 0,
    total_time: 60,
  });
  const [sectionArray, setSectionArray] = useState({
    subj: "",
    syllabusCatch: "",
    questiosn: "",
  });
  const totalNumber = 0;

  // subject id and syllabus id for query count
  const [questionCountFilter, setQuestionCountFilter] = useState({
    subject_id: null,
    syllabus_id: null,
  });

  const [questionCount, setQuestionCount] = useState(0);

  function calculateTotalQuestionsFromSection(data) {
    try {
      let totalQuestions = 0;
      for (const item of data) {
        totalQuestions += item.sectionQuestions;
      }
      console.log(totalQuestions, "totalQuestions");
      setCount(totalQuestions);
    } catch (error) {
      console.error("Error calculating total questions:", error);
    }
  }

  useEffect(() => {
    // console.log(location.state.value, 'sectionslocation.value');
    if (location.state?.value) {
      getIndividualPaper();
    }
  }, [location.state]);

  useEffect(() => {
    if (props.hasEdit && props.value) {
      getIndividualPaperFromProps();
    }
  }, [props]);

  useEffect(() => {
    console.log(props?.getRow, "dasds");
  }, [props?.getRow]);

  const handleTemplate = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: e.target.value,
    });
  };

  const handleSectionEdit = (e) => {
    console.log(e.target.value);
    setSections(sections?.map((obj) => {}));
  };

  const getSubject = () => {
    setBackdropLoader(true);
    api
      .getSubject()
      .then((res) => {
        setBackdropLoader(false);
        // console.log(res);
        setSubject(res.data.subject);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const getSyllabusBySub = (id) => {
    setBackdropLoader(true);
    api
      .getSyllabusBySub(id)
      .then((res) => {
        setBackdropLoader(false);
        console.log("syl", res);
        setSyllabus(res.data.syllabus);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const handleChangeSubject = (e) => {
    getSyllabusBySub(e.target.value);
    let index = e.nativeEvent.target.selectedIndex;
    let getSubjectText = e.nativeEvent.target[index].text;

    setSectionArray((current) => ({ ...current, subj: getSubjectText }));
    setQuestionForm({
      ...questionForm,
      [e.target.name]: e.target.value,
    });

    // e.target.value will contain the subject id
    // save the id on a state
    setQuestionCountFilter((prev) => ({
      ...prev,
      subject_id: e.target.value,
    }));
  };

  const handleChangeSyllabus = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let getSubjectText = e.nativeEvent.target[index].text;
    setQuestionForm({
      ...questionForm,
      [e.target.name]: e.target.value,
    });
    setSectionArray((current) => ({
      ...current,
      syllabusCatch: getSubjectText,
      syl_id: e.target.value,
    }));

    // syllabus id will be on e.target.value
    // save hte id on a state
    setQuestionCountFilter((prev) => ({
      ...prev,
      syllabus_id: e.target.value,
    }));
  };

  function getQuestionCount() {
    api
      .getQuestionCountForSubjectAndSyllabus({ ...questionCountFilter })
      .then((response) => {
        console.log("response on response", response);
        if (response.data.success) {
          console.log(response, "response for question count");
          setQuestionCount(response.data.data.count);
        }
      });
  }

  // run a useEffect everytime when
  // questioncount flter changes
  useEffect(() => {
    if (questionCountFilter.subject_id && questionCountFilter.syllabus_id) {
      getQuestionCount();
    }
  }, [questionCountFilter]);

  useEffect(() => {
    getSubject();
    // setQuesPerSection(totalQuestion && totalQuestion / sections?.length);
  }, []);

  const handleSectionQues = (e) => {
    setSectionArray((current) => ({
      ...current,
      questiosn: e.target.value.replace(/\D/g, ""),
    }));
    setSectionQues(e.target.value.replace(/\D/g, ""));
    // console.log(sectionArray && sectionArray, 'sectionArray');
  };

  // clean the whene delete sectionArrayId and questionForm
  const handleSectionDelete = (row) => {
    setSections((current) =>
      current.filter((data) => data.syllabus_id !== row.syl_id)
    );
    setSections((current) =>
      current.filter((data) => data.syllabus_id != row.syllabus_id)
    );
    setCount(Number(count && count) - parseInt(row.sectionQuestions));
  };

  const handleSections = () => {
    if (parseInt(sectionArray.questiosn) > template?.tot_questions) {
      Swal.fire({
        title: "Limit Exists",
        text: `Question number cant exist beyond ${parseInt(
          template?.tot_questions
        )}`,
      });
      return;
    }
    console.log(count && count, template?.tot_questions, "2totalNumber2");
    if (parseInt(count) + parseInt(sectionQues) > template?.tot_questions) {
      Swal.fire({
        title: "Limit Exists",
        text: `Question number cant exist beyond  ${parseInt(
          template?.tot_questions
        )}`,
      });
      return;
    }
    let objPresent;
    if (sections?.length > 0) {
      objPresent = sections.some(
        (obj) => Number(obj.syllabus_id) === Number(questionForm?.syllabus_id)
      );
      if (objPresent === true) {
        console.log(objPresent, "objPresent");
        Swal.fire({
          title: "Syllabus Already exist",
          text: "Please try another Syllabus ",
        });
        return;
      }
    }
    let sectionValeus = {
      subName: sectionArray?.subj,
      syllabus: sectionArray?.syllabusCatch,
      sectionQuestions: Number(sectionQues && sectionQues),
      subject_id: Number(questionForm?.subject_id),
      syllabus_id: Number(questionForm?.syllabus_id),
    };
    setSections((current) => [...current, sectionValeus]);
    setCount(parseInt(count) + parseInt(sectionQues));
    setBackdropLoader(false);
  };

  const handleSubmit = () => {
    console.log("==>", template);
    console.log("sections", sections && sections);
    // return;
    if (template.description === "") {
      setError({ ...error, description: "Description is Empty" });
      console.log(error && error, ";error");
      return;
    }
    if (template.marks_per_question === "") {
      setError({ ...error, marks_per_question: "Field is Empty" });
      return;
    }
    if (template.name === "") {
      setError({ ...error, name: "Name is Empty" });
      return;
    }
    if (template.tot_questions === "") {
      setError({ ...error, tot_questions: "Field is Empty" });
      return;
    }
    // if (sectionArrayId.length === 0) {
    //     setError({ ...error, sectionArrayId: 'Type Cannot be Empty' })
    //     return;
    // }

    api
      .createPaper(template, sections, props?.getRow ? props?.getRow : "")
      .then((res) => {
        console.log("ress==>>", res);

        let text = "";

        if (props.hasEdit === "edit") {
          props.updateList();
          props.handleEditClose();
          text = "Your Paper is updated.";
        } else {
          text = "Your Paper is Generated";
        }

        Swal.fire({
          title: "Paper Created",
          text,
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          title: "Something went Wrong",
          text: "Please try again",
        });
      });
  };

  const getIndividualPaper = () => {
    api
      .getIndividualPaper(location.state?.value.id)
      .then((res) => {
        console.log(res.data);
        setTemplate(res.data[0].paper[0]);
        setSections(res.data[0].sections);
        calculateTotalQuestionsFromSection(res.data[0].sections);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getIndividualPaperFromProps = () => {
    if (props.hasEdit && props.value) {
      api
        .getIndividualPaper(props.value.id)
        .then((res) => {
          console.log(res.data);
          setTemplate(res.data[0].paper[0]);
          setSections(res.data[0].sections);
          calculateTotalQuestionsFromSection(res.data[0].sections);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <LinearWithValueLabel /> */}
      <section>
        <div className="row">
          <Paper>
            <div className="my-3"></div>
            <div>
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">Add Paper Set </h3>
                  <small>
                    Please fill all the inputs and check Exam type and Paper
                    category before upload.
                  </small>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <div>
          <div className="row">
            <Paper sx={{ minHeight: "100vh" }}>
              <div className="col-12">
                <div className="p-4">
                  <PaperForm
                    hasEdit={props.hasEdit}
                    handleSectionEdit={handleSectionEdit}
                    sections={sections}
                    loader={loader}
                    getSubject={getSubject}
                    getSyllabusBySub={getSyllabusBySub}
                    subject={subject}
                    questionForm={questionForm}
                    handleChangeSubject={handleChangeSubject}
                    error={error}
                    syllabus={syllabus}
                    handleSections={handleSections}
                    handleSubmit={handleSubmit}
                    handleChangeSyllabus={handleChangeSyllabus}
                    sectionQues={sectionQues}
                    handleSectionQues={handleSectionQues}
                    template={template}
                    handleTemplate={handleTemplate}
                    count={count && count}
                    handleSectionDelete={handleSectionDelete}
                    hasProps={location.state?.hasEdit}
                    table={table}
                    setTable={setTable}
                    questionCount={questionCount}
                  />
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePaper;
