import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function OrganizationTable(props) {
  const Loading = () => {
    return (
      <>
        <div className="container p-2">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-6">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <h4 className="text-center">Organization/Institution List</h4>

      {props.loadingOrgTable ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="row my-4">
            {props.examType &&
              props.examType.map((exam) => (
                <div className="col-md-6">
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>{exam.examType}</StyledTableCell>
                          <StyledTableCell align="right">
                            Area Type
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.organizationOrg.map((row) => (
                          <>
                            {exam.id === row.examType_id && (
                              <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                  {row.organization_name}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.area_type}
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
