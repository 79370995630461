import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import policies from '../../API/policies/policies';
// Initially we will have a light mode
const initialState = {
    isLoading: true,
    list: [],
    status: 'loading',
    error: null
};

// Handle our action of changing the theme

export const PolicyFetchAPi = createAsyncThunk('getPolicyFetch/PolicyFetchAPi', async () => {
    const response = await policies.getPolicy()
    console.log(response, 'PolicyFetchAPi');
    return response.data;
})

export const getPolicyFetch = createSlice({
    name: 'PolicyFetch',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(PolicyFetchAPi.pending, (state, action) => {
                state.status = 'loading'
                state.isLoading = true
                console.log(initialState, 'initialState');
            })
            .addCase(PolicyFetchAPi.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.list = action.payload
                state.isLoading = false
                console.log(action, 'action.payload question');
            })
            .addCase(PolicyFetchAPi.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
                state.isLoading = false
                console.log(JSON.stringify(state),action, 'faisled');
            })
    }
})

export default getPolicyFetch.reducer;