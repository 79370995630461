import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RichText(props) {
  const editor = useRef(null);

  const config = {
    readonly: false, // Allows editing
    toolbarSticky: true, // Toolbar sticks on scroll
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: true,
    height: 400,
    uploader: {
      insertImageAsBase64URI: true, // Image upload as base64
    },
    // filebrowser: {
    //   ajax: {
    //     url: 'https://your-backend-url-to-handle-files', // Backend to handle file browsing/upload
    //   },
    //   buttons: ['file', 'image', 'video'], // File handling options
    // },
    toolbarAdaptive: false, // Show all toolbar options
    toolbar: true,
    buttons: [
      'source', '|', // View HTML source
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'ul', 'ol', '|', // Lists
      'outdent', 'indent', '|', // Indent/Outdent
      'font', 'fontsize', 'brush', 'paragraph', '|', // Font options
      'image', 'video', 'file', 'link', '|', // Media handling
      'align', 'undo', 'redo', '|', // Alignment and undo/redo
      'hr', 'eraser', 'copyformat', '|', // Additional options
      'symbol', 'fullsize', 'print', 'about' // Full screen, print, info
    ],
    buttonsXS: [
      'source', '|', 'bold', 'italic', 'underline', 'ul', 'ol', '|',
      'font', 'fontsize', '|', 'align', 'undo', 'redo', '|', 'image', 'link'
    ], // Toolbar on small screens
    spellcheck: true, // Enable spell checking
    allowResizeX: true, // Allow resizing horizontally
    allowResizeY: true, // Allow resizing vertically
  };

  const Jodit = () => {
    return useMemo(
      () => (
        <JoditEditor
          ref={editor}
          config={config}
          value={props.value}
          onChange={props.handleForm}
        />
      ),
      []
    );
  };

  return <>{Jodit()}</>;
}
