import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppUsersTable from "../table/appusers";
import api from "../../API/api";
import CustomTable from "../table/CustomTable";

const ExamWisePaper = (props) => {
  const [backdroploader, setBackdropLoader] = useState(false);
  const [loading, setLoading] = useState();
  const [selectedUsers, setSelectedUsers] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [exams, setExams] = useState();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const changeFilters = () => {};
  const handleChangePage = (newPage) => {
    setPage(newPage + 1);
    setLoading(true);
  };
  const handleRowClick = (row) => {
    if (selectedUsers[row.id]) {
      delete selectedUsers[row.id];
      setSelectedUsers({ ...selectedUsers });
    } else {
      setSelectedUsers({ ...selectedUsers, [row.id]: row });
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };
  const tableHeads = [
    {
      id: "id",
      label: "ID",
      editable: true,
      minWidth: 30,
      maxWidth: 80,
      onChange: (e) => changeFilters("id", e.target.value),
    },
    {
      id: "name",
      label: "Name",
      editable: true,
      minWidth: 100,
      onChange: (e) => changeFilters("name", e.target.value),
    },
    {
      id: "tot_questions",
      label: "Total Questions",
      editable: true,
      minWidth: 100,
      onChange: (e) => changeFilters("tot_questions", e.target.value),
    },
    // {
    //   id: "marks_per_question",
    //   label: "Marks Per Question",
    //   editable: true,
    //   minWidth: 100,
    //   onChange: (e) => changeFilters("marks_per_question", e.target.value),
    // },
    // {
    //   id: "negative_marks",
    //   label: "Negative Marks",
    //   editable: true,
    //   minWidth: 100,
    //   onChange: (e) => changeFilters("negative_marks", e.target.value),
    // },
    {
      id: "paper_type",
      label: "Paper Type",
      editable: true,
      minWidth: 100,
      onChange: (e) => changeFilters("paper_type", e.target.value),
    },
    {
      id: "paper_price",
      label: "Paper Price",
      editable: true,
      minWidth: 100,
      onChange: (e) => changeFilters("paper_price", e.target.value),
    },
    {
      id: "paper_category",
      label: "Paper Category",
      editable: true,
      minWidth: 100,
      onChange: (e) => changeFilters("paper_category", e.target.value),
    },
    {
      id: "isActive",
      label: "Is Active",
      editable: true,
      minWidth: 50,
      onChange: (e) => changeFilters("isActive", e.target.value),
    },
    {
      id: "exam_id",
      label: "Exam ID",
      editable: true,
      minWidth: 50,
      onChange: (e) => changeFilters("exam_id", e.target.value),
      renderRow: (row) => row.exam?.examName ?? row.exam?.name ?? "-",
    },
    {
      id: "added_by",
      label: "Added By",
      editable: true,
      minWidth: 100,
      onChange: (e) => changeFilters("added_by", e.target.value),
    },
  ];

  const fetchExams = () => {
    setBackdropLoader(true);
    api
      .fetchExams()
      .then((res) => {
        setExams(res.data);
        setBackdropLoader(false);
        console.log(res, "exams res");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchExams();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section>
        <div className="row">
          <Paper>
            <div className="my-2">
              <h3>App Users and Details </h3>
            </div>
          </Paper>
        </div>

        <div>
          <div className="row">
            <div className="col-12">
              <div style={{ margin: 5 }}>
                <CustomTable
                  tableRows={exams && exams ? exams : []}
                  tablePagination={exams}
                  tableHeads={tableHeads}
                  rowClick={handleRowClick}
                  selectedItems={selectedUsers}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExamWisePaper;
