import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { CustomTableCell } from "./TableCellGenerator";
import { CircularProgress, Skeleton } from "@mui/material";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <CustomTableCell
            key={index}
            tableCell={{
              ...headCell,
            }}
          />
          //   <TableCell
          //     key={headCell.id}
          //     align={headCell.numeric ? "right" : "left"}
          //     padding={headCell.disablePadding ? "none" : "normal"}
          //     // sortDirection={orderBy === headCell.id ? order : false}
          //   >
          //     {headCell.label}
          //   </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Nutrition
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/**
 * @function CustomTable
 *
 * @param {import("./custom_table__jsdoc_types").CustomTableProps} props
 *
 * @returns
 */
export default function CustomTable({
  title,
  tableHeads,
  tableRows,
  description,
  tablePagination,
  rowClick,
  selectedItems,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [allSelected, setAllSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleSelectAllClick = (event) => {};

  const handleClick = (event, row) => {
    rowClick(row);
  };

  const handleChangePageLocal = (event, newPage) => {
    setPage(newPage);
    handleChangePage(newPage);
  };

  const handleChangeRowsPerPageLocal = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleChangeRowsPerPage(event);
    handleChangePage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  //   const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const SkeletonRows = React.useMemo(() => {
    return [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        {tableHeads.map((head) => (
          <TableCell key={head.id} id={head.id}>
            <Skeleton variant="text" height={40}></Skeleton>
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [tableHeads]);

  console.log(tableHeads, "tableHeads");

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tableRows.length}
              headCells={tableHeads}
            />
            <TableBody>
              {loading && <>{SkeletonRows}</>}
              {!loading &&
                tableRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",

                        backgroundColor:
                          selectedItems && selectedItems[row.id]
                            ? "lightblue"
                            : "transparent",

                        "&.MuiTableRow-root:hover": {
                          backgroundColor: "lightblue",
                        },
                      }}
                    >
                      {tableHeads.map((tableHead, index) => {
                        if (tableHead.renderRow) {
                          console.log(tableHead.renderRow(row), "dasdsadhbsah");
                          return (
                            <TableCell key={index} id={row[tableHead]}>
                              {tableHead.renderRow(row)}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={index} id={row[tableHead]}>
                            {row[tableHead.id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tablePagination && tablePagination.total}
          rowsPerPage={
            tablePagination && tablePagination.per_page
              ? tablePagination.per_page
              : rowsPerPage
          }
          page={page}
          onPageChange={handleChangePageLocal}
          onRowsPerPageChange={handleChangeRowsPerPageLocal}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
