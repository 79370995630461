import React, { useEffect, useState } from "react";
import RichText from "../../pannel/richtext";
import policies from "../../../API/policies/policies";
import { useDispatch, useSelector } from "react-redux";
import { PolicyFetchAPi } from "../../../redux/reducer/policy";
import Swal from "sweetalert2";

const Privacy = () => {
  const [data, setData] = useState();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const policyData = useSelector((state) => state.getPolicyFetch);
  console.log(policyData, "policyData");

  const handleQuestion = (e) => {
    setData(e);
  };

  const handleEdit = () => setEdit(!edit);

  const postData = async () => {
    await policies
      .postPolicy(data, "privacy")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Swal.fire("Good job!", "data Updated", "success");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (policyData?.list.policy) {
      setData(policyData?.list.policy.privacy);
    }
  }, [policyData]);
  return (
    <>
      <div className="d-flex justify-content-around mb-5">
        <h2>privacy para</h2>
        <button className="btn btn-primary" onClick={handleEdit}>
          {!edit ? "Edit privacy policy" : "see data"}
        </button>
        {/* <button onClick={()=>dispatch(PolicyFetchAPi())}>refresh</button> */}
      </div>
      {edit ? (
        <>
          <RichText name="question" handleForm={handleQuestion} value={data} />
          <div className="text-center">
            <button
              onClick={postData}
              className="btn btn-primary m-5 text-center w-50"
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        policyData?.list.policy && (
          <div
            dangerouslySetInnerHTML={{
              __html: policyData?.list.policy.privacy,
            }}
          />
        )
      )}
    </>
  );
};

export default Privacy;
