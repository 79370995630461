import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import api from "../../../API/api";
import { useDispatch, useSelector } from "react-redux";
import { ExamsFetchApi } from "../../../redux/reducer/exam";

const ExamCategory = () => {
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getValue = useSelector((state) => state.ExamsFetchReducer);

  console.log(getValue, "get stpore valuyes");

  const ExamsFetch = () => {
    api
      .getExamType()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setExamData(res.data.examtype);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  useEffect(() => {}, []);

  return (
    <>
      <div className="examsLoader">
        <div className="items">
          <div>  <h2> exam category</h2></div>
          {getValue.isLoading ? (
            <div class="exams_categories">
            <p>Loading</p>
            </div>
          ) : (
            getValue.list.map((item, index) =><div class="exams_categories" key={index}> <p>{index+1}.{item.examType}</p></div>)
          )}
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default ExamCategory;
