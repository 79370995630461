import React, {useEffect} from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QuizIcon from '@mui/icons-material/Quiz';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/LearnOIndia_logo_PNG1.png"


const DashboardPage = () => {

    const navigate = useNavigate();


    useEffect(() => {
        // console.log("userr===>>> data", JSON.parse(sessionStorage.getItem('user')));
        document.title='Dashboard';
    }, [])
    return (
        <section>
            <div className="container">
                <div className='dashHeader'>
                    <div>
                        <small className='text-primary'>Role : {JSON.parse(sessionStorage.getItem('user')).role} </small>
                        <h4 > <strong> Hi,</strong> {JSON.parse(sessionStorage.getItem('user')).username} </h4>
                    </div>
                    <div className='logo-dash'>
                        <img  src={logo} />
                    </div>
                </div>
                {
                    JSON.parse(sessionStorage.getItem('user')).role === 'QC' ?
                    <div className="col-md-4">
                    <div className="my-3 set set2 " onClick={()=>navigate('/admin/AddQuestion')}>
                        <h5 ><QuizIcon /> &nbsp;Question Set</h5>
                    </div>
                </div>
                :
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <div  className="my-3 set" onClick={()=>navigate('/admin/name')}>
                            <h5 ><AttachmentIcon /> &nbsp;Exam Set</h5>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="my-3 set set1" onClick={()=>navigate('/admin/subject')}>
                            <h5 ><AutoStoriesIcon /> &nbsp;Subject Set</h5>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="my-3 set set2" onClick={()=>navigate('/admin/AddQuestion')}>
                            <h5 ><QuizIcon /> &nbsp;Question Set</h5>
                        </div>
                    </div>
                </div>
                }
                <div>
                    <h5> <b> Daily Updates</b> </h5>
                        {/* <div className='col-md-6'> */}
                    <div className="row">
                            <div className="col-md-3">
                                <div className="box my-3 p-5 text-center text-white">
                                    <p>10+</p>
                                    <h5>Tests</h5>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box my-3 p-5 text-center text-white">
                                    <p>10+</p>
                                    <h5>Subjects</h5>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box my-3 p-5 text-center text-white">
                                    <p>100+</p>
                                    <h5>Questions</h5>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box my-3 p-5 text-center text-white">
                                    <p>10+</p>
                                    <h5>Rewards</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                <div>
                    <div>
                        <h5><b> Quick Links </b></h5>
                    </div>
                    <div className="row ">
                        {/* <div className="col-md-6"> */}
                        {
                               JSON.parse(sessionStorage.getItem('user')).role === 'QC' ? 
                               <div className="col-md-3">
                               <div className="examBtn my-3 p-3 text-center" onClick={()=>navigate('/admin/AddQuestion')}>
                                   <h5>+ Add Questions</h5>
                               </div>
                           </div>
                           :
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <div className="examBtn my-3 p-3 text-center" onClick={()=>navigate('/admin/type')}>
                                        <h5>+ Add Exam Type</h5>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="examBtn my-3 p-3 text-center" onClick={()=>navigate('/admin/add_exam')}>
                                        <h5>+ Add Exam Name</h5>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="examBtn my-3 p-3 text-center"  onClick={()=>navigate('/admin/subject')}>
                                        <h5>+ Add Subjects</h5>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="examBtn my-3 p-3 text-center" onClick={()=>navigate('/admin/AddQuestion')}>
                                        <h5>+ Add Questions</h5>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* </div> */}
                        {/* <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="box my-3 p-5 text-center text-white">
                                        <p>10+</p>
                                        <h5>Tests</h5>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box my-3 p-5 text-center text-white">
                                        <p>10+</p>
                                        <h5>Subjects</h5>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box my-3 p-5 text-center text-white">
                                        <p>100+</p>
                                        <h5>Questions</h5>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box my-3 p-5 text-center text-white">
                                        <p>10+</p>
                                        <h5>Rewards</h5>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DashboardPage;