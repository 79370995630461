import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ExamModal = (props) => {
  return (
    <>
      <Modal
        open={props.open}
        onClose={
          props.handleClose
            ? props.handleClose
            : () => {
                console.log("handle closed not passed");
              }
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl fullWidth size="small" variant="standard">
              <InputLabel id="demo-select-small-label">Exam Type</InputLabel>
              {props?.getExamTypes.isLoading ? (
                <p>loading</p>
              ) : (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.name.examType}
                  label="Organization"
                  onChange={props.onChangeExamType}
                  name="examtype"
                >
                  {props?.getExamTypes?.list.map((exam) => (
                    <MenuItem value={exam.id}>{exam.examType}</MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            {props.forLinkingPaper && (
              <FormControl fullWidth size="small" variant="standard">
                <InputLabel id="demo-select-small-label">Exam Name </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.name.examName}
                  label="Organization"
                  onChange={props.onChangeExamName}
                  name="examName"
                >
                  {props.examNames &&
                    props.examNames.map((exam) => (
                      <MenuItem value={exam.id}>{exam.examName}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {!props.forLinkingPaper && (
              <FormControl fullWidth size="small" variant="standard">
                <InputLabel id="demo-select-small-label">
                  Organisation/ institution{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.name.institute}
                  label="Organization"
                  onChange={props?.onChanegOrganization}
                  name="institute"
                >
                  {props.organization &&
                    props.organization.map((exam) => (
                      <MenuItem value={exam.id}>
                        {exam.organization_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {!props?.forLinkingPaper ? (
              <>
                <label>Upload Icon</label>
                <input
                  type="file"
                  name="image"
                  onChange={props?.handleImageUpload}
                />
                {props.preview && (
                  <img src={props.preview} height={100} width={100} />
                )}
                <TextField
                  id="outlined-multiline-flexible"
                  label="Exam Name"
                  size="small"
                  className="form-control"
                  variant="standard"
                  name="examName"
                  onChange={props.handleInput}
                  value={props.name.examName}
                />

                <TextField
                  id="outlined-multiline-flexible"
                  label="Specialization"
                  size="small"
                  className="form-control"
                  variant="standard"
                  name="specialization"
                  onChange={props.handleInput}
                  value={props.name.specialization}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Grade"
                  size="small"
                  className="form-control"
                  variant="standard"
                  name="grade"
                  onChange={props.handleInput}
                  value={props.name.grade}
                />
              </>
            ) : null}
          </Box>

          <div className="text-center">
            {props.update ? (
              <Button
                variant="contained"
                className="my-3"
                onClick={() => props.handleUpdate(props.name.id)}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                className="my-3"
                onClick={props.handleSubmit}
                disabled={props.isSubmitLoading ? true : false}
              >
                {props.isSubmitLoading ? "Loading..." : "Submit"}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ExamModal;
