import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../API/api';
// Initially we will have a light mode
const initialState = {
    isLoading: true,
    list: [],
    status: 'loading',
    error: null
};

// Handle our action of changing the theme

export const QuestionFetchApi = createAsyncThunk('QuestionFetchSLice/QuestionFetchApi', async () => {
    const response = await api.getExamType()
    console.log(response, 'medals list2');
    return response.data;
})

export const QuestionFetchSLice = createSlice({
    name: 'QuestionFetch',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(QuestionFetchApi.pending, (state, action) => {
                state.status = 'loading'
                console.log(initialState, 'initialState');
            })
            .addCase(QuestionFetchApi.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.list = action.payload
                console.log(action, 'action.payload question');
            })
            .addCase(QuestionFetchApi.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
                console.log(JSON.stringify(state),action, 'faisled');
            })
    }
})

export default QuestionFetchSLice.reducer;