import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function OrganizationModal(props) {
  const [openOrg, setOpenOrg] = React.useState(false);

  useEffect(() => {
    setOpenOrg(false);
  }, [props.modalOpen]);

  const handleClickOpen = () => {
    setOpenOrg(true);
  };

  const handleOrgClose = () => {
    setOpenOrg(false);
  };

  const Loading = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        + Organization/Institute
      </Button>
      <Dialog
        open={openOrg}
        onClose={handleOrgClose}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Organization/Institute under {props.exam.examType}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <div className="row">
                <div className="col-6">
                  <div>
                    <label htmlFor="">Board Name </label>
                    <input
                      type="text"
                      className="form-control"
                      name="organization_name"
                      value={props.organization.organization_name}
                      onChange={props.handleOrganization}
                      id=""
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <label htmlFor="">Area Type</label>
                    <select
                      name="area_type"
                      value={props.organization.area_type}
                      onChange={props.handleOrganization}
                      className="form-control"
                      id=""
                    >
                      <option value="">--select--</option>
                      {/* <option value="District">District</option> */}
                      <option value="State">State</option>
                      <option value="Central">Central</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.orgLoading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              <Button onClick={handleOrgClose}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => props.saveOrganization(props.exam.id)}
                autoFocus
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
