import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Divider } from "@mui/material";
const htmlToFormattedText = require("html-to-formatted-text");

export default function PopOverMaster({ component, text, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(data && data, "data");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <IconButton
          aria-label="info"
          aria-describedby={id}
          onClick={handleClick}
        >
          <InfoIcon />
        </IconButton>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{text}</Typography>
        {data !== null &&
          Object.entries(data).map(([key, value]) => (
            <Typography
              sx={{ p: 2 }}
              key={key}
            >{`${key}: ${value}`}</Typography>
          ))}

        {component}
      </Popover>
    </div>
  );
}
