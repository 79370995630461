import { configureStore } from '@reduxjs/toolkit'

// import rootReducers from "./reducer";
import  QuestionFetchSLice  from './reducer/fetchQuestions';
import ExamsFetchReducer  from './reducer/exam';
import getPolicyFetch from './reducer/policy';


export const store = configureStore({
    reducer : {
        QuestionFetchSLice,ExamsFetchReducer ,getPolicyFetch
    }
});

export default store;