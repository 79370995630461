import API from "../apiConfig";

export default {
  // APP USER RELATED
  postPolicy(data, type) {
    return API.post("/updatePolicy", { data, type });
  },
  getPolicy() {
    return API.get("/getPolicy");
  },
};
