import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import api from "../../API/api";
import Swal from "sweetalert2";
import OrganizationModal from "../modal/organization";
import OrganizationTable from "../table/organizationTable";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LinearProgress from "@mui/material/LinearProgress";
import DialogModal from "../modal/modal";

const AddExamType = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalOpen, setModalOpen] = useState(true);
  // const [openOrg, setOpenOrg] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpenOrg(true);
  // };

  // const handleOrgClose = () => {
  //     setOpenOrg(false);
  //   };

  const [formData, setFormData] = React.useState({
    examType: "",
    examTypeId: "",
  });

  const [organization, setOrganization] = useState({});
  const [preview, setPreview] = useState();
  const [examData, setExamData] = useState([]);
  const [icon, setIcon] = useState("");
  const [loading, setLoading] = useState(true);

  const [orgLoading, setOrgLoading] = useState(false);

  useEffect(() => {
    viewData();
  }, []);

  const viewData = () => {
    api
      .getExamType()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setExamData(res.data.examtype);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleOrganization = (e) => {
    console.log(e.target.value);
    setOrganization({
      ...organization,
      [e.target.name]: e.target.value,
    });
  };

  const saveOrganization = (examType_id) => {
    setOrgLoading(true);
    api
      .postOrganization(organization, examType_id)
      .then((res) => {
        console.log(res);
        if (res.data.status == 200) {
          getData();
          // handleOrgClose();
          setModalOpen(false);
          setOrganization({});
          setOrgLoading(false);
          Swal.fire("Added Successfully!", res.data.message, "success");
        } else {
          Swal.fire("error", "Something Went Wrong", "error");
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log(formData, "formData");

    const postData = new FormData();
    postData.append("examType", formData.examType);
    if (icon) {
      postData.append("image", icon);
    }
    if (formData.examTypeId) {
      postData.append("id", formData.examTypeId);
    }

    api
      .postExamType(postData)
      .then((res) => {
        console.log(res);
        viewData();
        handleClose();
        setFormData({});
        Swal.fire("Good job!", "Registered Successfully!", "success");
      })
      .catch((err) => {
        console.log(err.response);
      });

    // axios.post(`${process.env.REACT_APP_MAIN_API}/postExamType`, postData)
    //     .then((res) => {

    //         console.log(res);
    //         viewData();
    //         handleClose();
    //         setFormData({});
    //         Swal.fire(
    //             'Good job!',
    //             'Registered Successfully!',
    //             'success'
    //         )
    //     })
    //     .catch((err) => {
    //         console.log(err.response);
    //     })
  };

  useEffect(() => {
    getData();
  }, []);

  const [organizationOrg, setOrganizationOrg] = useState();
  const [examType, setExamType] = useState();
  const [loadingOrgTable, setLoadingOrgTable] = useState(true);

  const getData = () => {
    api
      .getOrganization()
      .then((res) => {
        console.log(res);
        setLoadingOrgTable(false);
        setOrganizationOrg(res.data.organization);
        setExamType(res.data.examType);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const Loading = () => {
    return (
      <>
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12">
              <div className="p-4 row">
                <div className="col-md-12">
                  <Skeleton
                    height={35}
                    width={200}
                    count={1}
                    style={{ marginBottom: 10, float: "right" }}
                  />
                </div>
                <div className="col-md-12">
                  <Skeleton height={35} count={1} style={{ marginBottom: 6 }} />
                </div>
                <div className="col-md-4 my-2">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const handleImg = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setIcon(e.target.files[0]);
  };

  const handleEditExamType = (data) => {
    console.log(data);
    setIcon();
    setPreview();
    setFormData({
      ...formData,
      examType: data.examType,
      examTypeId: data.id,
    });
    setOpen(true);
  };

  return (
    <>
      {loading ? (
        <>
          <LinearProgress color="secondary" />
          <Loading />
        </>
      ) : (
        <>
          <section>
            <div>
              <Button
                onClick={handleOpen}
                variant="contained"
                className="my-3"
                style={{ float: "right" }}
                size="small"
              >
                + Add Exam Type
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Exam Type"
                      size="small"
                      className="form-control"
                      variant="standard"
                      onChange={handleInput}
                      value={formData.examType}
                      name="examType"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <label>Add icon</label>
                    <input type="file" onChange={handleImg} />
                    {preview && (
                      <img
                        src={preview}
                        height="100"
                        width="100"
                        style={{ objectFit: "contain" }}
                      />
                    )}
                  </Box>
                  <div className="text-center">
                    <Button
                      variant="contained"
                      className="my-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </Modal>
            </div>

            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">ID</StyledTableCell>
                      <StyledTableCell align="center">
                        Exam Type
                      </StyledTableCell>
                      <StyledTableCell align="center">Icon</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {examData.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.examType}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <img
                            src={`${process.env.REACT_APP_BACKEND}examType/icons/${row.icon}`}
                            height="50px"
                            width="100px"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-success btn-sm"
                              title="edit"
                              onClick={() => handleEditExamType(row)}
                            >
                              edit
                            </button>
                            {/* <DriveFileRenameOutlineIcon fontSize="small" />  */}
                            <OrganizationModal
                              exam={row}
                              organization={organization}
                              handleOrganization={handleOrganization}
                              saveOrganization={saveOrganization}
                              orgLoading={orgLoading}
                              modalOpen={modalOpen}
                            />
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </section>

          <section>
            <div className="mt-5">
              <OrganizationTable
                organizationOrg={organizationOrg}
                examType={examType}
                loadingOrgTable={loadingOrgTable}
              />
            </div>
          </section>
        </>
      )}
      {/* edit exam type */}
      <DialogModal
        form={<p>dasjb</p>}
        // open={open}
        // handleOpenModal={handleOpenModal}
        // handleCloseMove={handleCloseModal}
      />
      {/* edit exam type */}
    </>
  );
};

export default AddExamType;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
