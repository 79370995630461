import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import InfoQues from "../question/infoQues";
import MenuAction from "../question/menuActions";
import Pagination from "react-bootstrap/Pagination";

const headCells = [
  {
    id: "sl no",
    numeric: false,
    disablePadding: true,
    label: "sl no",
  },
  {
    id: "Question",
    numeric: true,
    disablePadding: false,
    label: "Question",
  },
  {
    id: "answers",
    numeric: true,
    disablePadding: false,
    label: "answers",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
  //     props;
  // const createSortHandler = (property) => (event) => {
  //     onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
            sx={{ padding: 2 }}
          >
            <TableSortLabel
            // active={orderBy === headCell.id}
            // direction={orderBy === headCell.id ? order : 'asc'}
            // onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  all,
  handleDelete,
  handleApprove,
  handleClickEdit,
  handleDeleteModal,
  page,
  setPage,
  setPerPage,
  perPage,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  console.log(all?.data, "how data isshwoing");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = all?.data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    console.log(newPage, "newPage");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
    console.log(parseInt(event.target.value, 10), "rows per page");
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - all?.data.length) : 0;

  // const visibleRows = React.useMemo(
  //     () =>
  //         stableSort(all?.data, getComparator(order, orderBy)).slice(
  //             page * rowsPerPage,
  //             page * rowsPerPage + rowsPerPage,
  //         ),
  //     [order, orderBy, page, rowsPerPage],
  // );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={all?.data?.length}
            />
            <TableBody>
              {/* {all?.data.map((row, index) => { */}

              {all?.data &&
                Object.values(all?.data).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th">{index + 1}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="w-50"
                        sx={{ width: "20%", inlineSize: 600 }}
                      >
                        {row.type === 3 ? (
                          <math-field
                            onInput={(evt) => row.question}
                            readonly
                            style={{ border: 0 }}
                          >
                            {row.question}
                          </math-field>
                        ) : (
                          <b>
                            <strong className="inline-600">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.question,
                                }}
                                className="inline-600"
                              />
                            </strong>
                          </b>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <div className="row align-items-center">
                          <div className="col">
                            {row?.ans.map((el, index) => (
                              <>
                                {el.question_type === 2
                                  ? el.isRight == 1 && (
                                      <div key={index}>
                                        <img
                                          style={{ height: 50, width: 50 }}
                                          src={`${process.env.REACT_APP_BACKEND}answers/img/${el.answer}`}
                                        />
                                      </div>
                                    )
                                  : el.question_type === 3
                                  ? el.isRight == 1 && (
                                      <b>
                                        <math-field
                                          onInput={(evt) => row.question}
                                          readonly
                                          style={{ border: 0 }}
                                          key={index}
                                        >
                                          {el.answer}
                                        </math-field>
                                      </b>
                                    )
                                  : el.isRight == 1 && (
                                      <div
                                        key={index}
                                        className={
                                          el.isRight
                                            ? "text-success"
                                            : "text-danger"
                                        }
                                      >
                                        <strong>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: el.answer,
                                            }}
                                          />
                                        </strong>
                                      </div>
                                    )}
                              </>
                            ))}
                          </div>
                          <div className="col">
                            <InfoQues ans={row} />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        {row?.isApprove ? (
                          <>
                            <span className="bg-success m-1 px-1 rounded text-white">
                              Approved
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="bg-danger m-1 px-3 rounded text-white">
                              Draft
                            </span>
                          </>
                        )}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        <MenuAction
                          data={row}
                          handleClickEdit={handleClickEdit}
                          handleDeleteModal={handleDeleteModal}
                          handleApprove={handleApprove}
                          // ref={childRef}
                          handleDelete={handleDelete}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={all?.total}
          rowsPerPage={all?.per_page}
          page={all?.current_page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Pagination>
          {/* <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Ellipsis /> */}
          {/* {
                        all?.links.map((item, index) => (
                            item.url !== null &&
                            <Pagination.Item key={index} onClick={e =>console.log(item.label,'onlcikd')}>
                                {console.log(item, 'items')}
                                {String(item.label)}
                            </Pagination.Item>
                        ))
                    } */}
          {/* <Pagination.Item disabled>{14}</Pagination.Item> */}

          {/* <Pagination.Ellipsis />
                    <Pagination.Item>{20}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last /> */}
        </Pagination>
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
