import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dash from "./AdminPages/dashboard/dash";
import AddExamType from "./AdminPages/Pages/examtype";
import AddSubject from "./AdminPages/Pages/subject";
import Papers from "./AdminPages/Pages/paper";
import AddQuestionPage from "./AdminPages/Pages/addQuestionPage";
import DashboardPage from "./AdminPages/dashBoardPage";
import QuizPage from "./PublicPages/quizHomePage";
import ExamPage from "./PublicPages/examPage";
import AddSyllabus from "./AdminPages/Pages/addSyllabus";
import ViewQuestions from "./AdminPages/question/viewQuestions";
import AddQuestionTemplate from "./AdminPages/Pages/addQuesTemplate";
import AddUsers from "./AdminPages/Pages/users/addUser";
import ListUsers from "./AdminPages/Pages/users/listUsers";
import Login from "./login";
import EditQuestionPage from "./AdminPages/Pages/editQuestionPage";
import TemplatesList from "./AdminPages/table/templates";
import SetQuestionPaper from "./AdminPages/questionPaper/setQuestionPaper";
import GeneratedPaper from "./AdminPages/questionPaper/generatedPapaer";
import QuestionList from "./AdminPages/table/questionsAll";
import { ProSidebarProvider } from "react-pro-sidebar";
import AuthUser from "./API/token";
import EditQuestionSets from "./AdminPages/qtemplate/editSets";
import QuestionsFromExcel from "./AdminPages/Pages/QuestionsFromExcel";
import EditExcelQuestions from "./AdminPages/Pages/EditExcelQuestions";
import StudyMaterial from "./AdminPages/Pages/studyMaterial";
import StudyMaterialForm from "./AdminPages/form/studyMaterial";
import Reports from "./AdminPages/Pages/reports";
import TestPaper from "./AdminPages/question/questionTest";
import AddQuestionMaths from "./AdminPages/form/addMaths";
import AppUsers from "./AdminPages/Pages/appUsers";
import PointsMaster from "./AdminPages/Pages/points";
import RewardMaster from "./AdminPages/Pages/rewards";

import CreatePaper from "./AdminPages/Paper/createPaper";
import PaperPage from "./AdminPages/Paper/list";
import AppPackages from "./AdminPages/Pages/appPackage";

import NewsLetterSubscribersPage from "./AdminPages/newsletter/NewsletterSubscribers";

import store from "../src/redux/store";
import { Provider, useDispatch } from "react-redux";
import AppUserSubscribers from "./AdminPages/appUserSubscriptions/AppUserSubscribers";
import PushNotificationPage from "./AdminPages/notifications/push_notification.page";
import StudyMaterialUpdateForm from "./AdminPages/form/studyMaterialUpdateForm";
import LivePaperIndex from "./AdminPages/Paper/live-paper/live-paper.index";
import AppUsersList from "./AdminPages/app-users/app-users-list.page";
import AppUserInfo from "./AdminPages/app-users/app-user-info.page";
import CreateExamPaper from "./AdminPages/exams";
import ExamCategory from "./AdminPages/exams/component/categories";
import { ExamsFetchApi } from "./redux/reducer/exam";
import AddExamName from "./AdminPages/exams/examname";
import ExamWisePaper from "./AdminPages/Pages/examWisePapers";
import Terms from "./AdminPages/Pages/polices/terms";
import Privacy from "./AdminPages/Pages/polices/privacy";
import Refund from "./AdminPages/Pages/polices/refund";
import About from "./AdminPages/Pages/polices/about";
import { PolicyFetchAPi } from "./redux/reducer/policy";

function App() {
  const { getToken, user } = AuthUser();
  const dispatch = useDispatch();

  dispatch(ExamsFetchApi());
  dispatch(PolicyFetchAPi());
  return (
    <Provider store={store}>
      <ProSidebarProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/quiz" element={<QuizPage />} />
          <Route path="/examPage" element={<ExamPage />} />
        </Routes>
        {getToken() && (
          <Routes>
            {/* super admin  */}
            {user?.role == "SA" && (
              <Route path="/admin" element={<Dash />}>
                <Route path="/admin" element={<DashboardPage />} />
                <Route path="add_exam" element={<AddExamName />} />
                <Route path="type" element={<AddExamType />} />
                <Route path="subject" element={<AddSubject />} />
                <Route path="Papers" element={<Papers />} />
                <Route path="viewQuestion" element={<ViewQuestions />} />
                <Route
                  path="viewQuestion/:sub/:id/:selfId"
                  element={<ViewQuestions />}
                />
                <Route path="AddQuestion" element={<AddQuestionPage />} />
                <Route
                  path="AddQuestion/:sub/:id/:selfId"
                  element={<AddQuestionPage />}
                />
                <Route path="subject/:sub/:id" element={<AddSyllabus />} />
                <Route
                  path="subject/:sub/:id/:selfId"
                  element={<AddSyllabus />}
                />
                {/* <Route path='questions_templates' element={<QuestionTemplate />} /> */}
                <Route
                  path="add__question_sets"
                  element={<AddQuestionTemplate />}
                />
                <Route path="createPaper" element={<CreatePaper />} />
                <Route path="addUsers" element={<AddUsers />} />
                <Route path="listUsers" element={<ListUsers />} />
                <Route
                  path="editQuestionsPage"
                  element={<EditQuestionPage />}
                />
                <Route path="templatesList" element={<TemplatesList />} />
                <Route path="SetQuestionPaper" element={<SetQuestionPaper />} />
                <Route path="GeneratedPaper" element={<GeneratedPaper />} />
                <Route path="questionsList" element={<QuestionList />} />
                <Route path="editQuestionSets" element={<EditQuestionSets />} />
                <Route
                  path="QuestionsFromExcel"
                  element={<QuestionsFromExcel />}
                />
                <Route
                  path="EditExcelQuestions"
                  element={<EditExcelQuestions />}
                />
                <Route path="StudyMaterial" element={<StudyMaterial />} />

                <Route
                  path="StudyMaterial/update/:study_material_id"
                  element={
                    <StudyMaterialUpdateForm title={"Update Study Material"} />
                  }
                />
                <Route
                  path="StudyMaterialForm"
                  element={<StudyMaterialForm />}
                />
                <Route path="Reports" element={<Reports />} />
                <Route path="TestPaper" element={<TestPaper />} />
                <Route path="PaperPage" element={<PaperPage />} />
                <Route path="AddQuestionMaths" element={<AddQuestionMaths />} />
                <Route path="app-users" element={<AppUsersList />} />
                <Route path="app-users/:id" element={<AppUserInfo />} />

                <Route path="PointsMaster" element={<PointsMaster />} />
                <Route path="RewardMaster" element={<RewardMaster />} />
                <Route path="AppPackages" element={<AppPackages />} />
                {/* Notifications routes */}
                <Route
                  path="notifications"
                  element={<div>Notifications</div>}
                />
                <Route
                  path="notifications/push"
                  element={<PushNotificationPage />}
                />
                {/* End Notifications routes */}
                {/* News Letter routes */}
                <Route
                  path="newsletter/subscribers"
                  element={<NewsLetterSubscribersPage />}
                />

                {/* App User Subscribers list */}
                <Route
                  path="app_user/subscribers"
                  element={<AppUserSubscribers />}
                />

                {/* Live Paper Routes */}
                <Route path="live-paper" element={<LivePaperIndex />} />
                <Route path="ExamWisePaper" element={<ExamWisePaper />} />
                <Route path="exams" element={<ExamCategory />}>
                  <Route path="CreateExamPaper" element={<CreateExamPaper />} />
                </Route>
                <Route path="polices">
                  <Route path="terms" element={<Terms />} />
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="refund" element={<Refund />} />
                  <Route path="about" element={<About />} />
                </Route>
              </Route>
            )}

            {user?.role == "Admin" && (
              <Route path="/admin" element={<Dash />}>
                <Route path="/admin" element={<DashboardPage />} />
                {/* this is fo exam add edit lsit */}
                <Route path="add_exam" element={<AddExamName />} />
                {/* this is fo exam add edit lsit */}
                <Route path="type" element={<AddExamType />} />
                <Route path="subject" element={<AddSubject />} />
                <Route path="Papers" element={<Papers />} />
                <Route path="viewQuestion" element={<ViewQuestions />} />
                <Route
                  path="viewQuestion/:sub/:id/:selfId"
                  element={<ViewQuestions />}
                />
                <Route path="AddQuestion" element={<AddQuestionPage />} />
                <Route
                  path="AddQuestion/:sub/:id/:selfId"
                  element={<AddQuestionPage />}
                />
                <Route path="subject/:sub/:id" element={<AddSyllabus />} />
                <Route
                  path="subject/:sub/:id/:selfId"
                  element={<AddSyllabus />}
                />
                {/* <Route path='questions_templates' element={<QuestionTemplate />} /> */}
                <Route
                  path="add__question_sets"
                  element={<AddQuestionTemplate />}
                />
                <Route path="createPaper" element={<CreatePaper />} />
                {/* <Route path="addUsers" element={<AddUsers />} /> */}
                <Route path="listUsers" element={<ListUsers />} />
                <Route
                  path="editQuestionsPage"
                  element={<EditQuestionPage />}
                />
                <Route path="templatesList" element={<TemplatesList />} />
                <Route path="SetQuestionPaper" element={<SetQuestionPaper />} />
                <Route path="GeneratedPaper" element={<GeneratedPaper />} />
                <Route path="questionsList" element={<QuestionList />} />
                <Route path="editQuestionSets" element={<EditQuestionSets />} />
                <Route
                  path="QuestionsFromExcel"
                  element={<QuestionsFromExcel />}
                />
                <Route
                  path="EditExcelQuestions"
                  element={<EditExcelQuestions />}
                />
                <Route path="StudyMaterial" element={<StudyMaterial />} />

                <Route
                  path="StudyMaterial/update/:id"
                  element={<div>update this big boi</div>}
                />
                <Route
                  path="StudyMaterialForm"
                  element={<StudyMaterialForm />}
                />
                <Route path="Reports" element={<Reports />} />
                <Route path="TestPaper" element={<TestPaper />} />
                <Route path="PaperPage" element={<PaperPage />} />
                <Route path="AddQuestionMaths" element={<AddQuestionMaths />} />
                <Route path="AppUsers" element={<AppUsers />} />
                <Route path="PointsMaster" element={<PointsMaster />} />
                <Route path="RewardMaster" element={<RewardMaster />} />
                <Route path="AppPackages" element={<AppPackages />} />
              </Route>
            )}

            {user?.role == "QC" && (
              <Route path="/admin" element={<Dash />}>
                <Route path="/admin" element={<DashboardPage />} />
                <Route path="name" element={<AddExamName />} />
                <Route path="type" element={<AddExamType />} />
                <Route path="subject" element={<AddSubject />} />
                <Route path="Papers" element={<Papers />} />
                <Route path="viewQuestion" element={<ViewQuestions />} />
                <Route
                  path="viewQuestion/:sub/:id/:selfId"
                  element={<ViewQuestions />}
                />
                <Route path="AddQuestion" element={<AddQuestionPage />} />
                <Route
                  path="AddQuestion/:sub/:id/:selfId"
                  element={<AddQuestionPage />}
                />
                <Route path="subject/:sub/:id" element={<AddSyllabus />} />
                <Route
                  path="subject/:sub/:id/:selfId"
                  element={<AddSyllabus />}
                />
                <Route path="questionsList" element={<QuestionList />} />
                <Route
                  path="editQuestionsPage"
                  element={<EditQuestionPage />}
                />
              </Route>
            )}
          </Routes>
        )}

        {!getToken() && (
          <Routes>
            <Route path="/admin/*" element={<Login />} />
          </Routes>
        )}
      </ProSidebarProvider>
    </Provider>
  );
}

export default App;
